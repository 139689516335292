import { useAuthUser } from "next-firebase-auth";
import Link from "next/link";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";

import { Row, Col, InputGroup, Input, Button } from "reactstrap";
import * as ga from "../lib/ga";

export default function Footer() {
  const AuthUser = useAuthUser();

  //TODO ideas https://www.creative-tim.com/learning-lab/reactstrap/core-footers/argon-design-system

  return (
    <>
      <div className="footer">
        <hr style={{ height: "2px" }} />
        <Row xs="2" sm="4" md="4">
          <Col>
            <h6>About</h6>
            <Link href="/service">Our service</Link>
            <br />
            <Link href="/beliefs">What we believe</Link>
            <br />
            <Link href="/contact">Contact us</Link>
            <br />
            <br />
          </Col>
          <Col>
            <h6>Links</h6>
            {/*<Link href="/faq">FAQ</Link>
            <br />*/}
            <Link href="/privacy">Privacy policy</Link>
          </Col>
          <Col xs="12" sm="6" md="6">
            <h6>Follow</h6>
            <p>
              <a
                href="https://twitter.com/vacancieschurch"
                aria-label="Twitter"
              >
                <FontAwesomeIcon
                  icon={faTwitter as IconProp}
                  style={{ fontSize: 20, height: 20, width: 20 }}
                />
              </a>{" "}
              <a
                href="https://www.facebook.com/vacancies.church/"
                aria-label="Facebook"
              >
                <FontAwesomeIcon
                  icon={faFacebook as IconProp}
                  style={{ fontSize: 20, height: 20, width: 20 }}
                />
              </a>
            </p>
            <form
              action="https://church.us20.list-manage.com/subscribe/post?u=9bba9c4258bcbfa20916da9d6&amp;id=1703fbea74"
              method="post"
              name="mc-embedded-subscribe-form"
              target="_blank"
              noValidate
            >
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_9bba9c4258bcbfa20916da9d6_1703fbea74"
                  tabIndex={-1}
                  defaultValue=""
                />
              </div>
              <InputGroup style={{ maxWidth: "350px" }}>
                <Input
                  name="EMAIL"
                  id="mce-EMAIL"
                  placeholder="email address"
                  required
                />
                <Button
                  //color="warning"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    ga.event({
                      action: "mailchimp_subscribe",
                      params: {
                        location: "footer",
                      },
                    });
                  }}
                >
                  Subscribe
                </Button>
              </InputGroup>
            </form>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#ffffff",
            marginTop: "5px",
          }}
        >
          <span style={{ marginRight: 5 }}>
            <a
              href="https://pomegranate7.com/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Pomegranate 7"
            >
              <Image
                height="23"
                width="20"
                //style={{ marginTop: -8 }}
                src="https://emalive.s3-eu-west-1.amazonaws.com/images/P7_logo_white.png"
                className="p7-logo"
                alt="Pomegranate 7 logo"
              />
            </a>
          </span>
          <span>&copy; {new Date().getFullYear()}</span>
        </div>
      </div>
    </>
  );
}
