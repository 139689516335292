import React from "react";
import {
  useAuthUser,
  withAuthUser,
  withAuthUserTokenSSR,
} from "next-firebase-auth";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Head from "next/head";
import { Container, Row, Col, Button, InputGroup, Input } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faEquals } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";

const Home = () => {
  const AuthUser = useAuthUser();
  return (
    <>
      <Header />
      <div
        style={{
          position: "relative",
          backgroundColor: "black",
          height: "70vh",
          minHeight: "25rem",
          //width: "100%",
          overflow: "hidden",
          top: "64x",
          margin: "10px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "black",
            opacity: 0.5,
            zIndex: 1,
          }}
        ></div>
        <video
          playsInline
          autoPlay
          muted
          loop={true}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            minWidth: "100%",
            minHeight: "100%",
            width: "auto",
            height: "auto",
            zIndex: 0,
            //-msTransform: translateX(-50%) translateY(-50%);
            //-moz-transform: translateX(-50%) translateY(-50%);
            //-webkit-transform: translateX(-50%) translateY(-50%);
            transform: "translateX(-50%) translateY(-50%)",
          }}
        >
          <source
            id="v1"
            src="https://s3.eu-west-1.amazonaws.com/vacancies.church/vacancies.mp4"
            type="video/mp4"
          />
        </video>
        <div className="container h-100 intro-text">
          <div className="d-flex h-25 text-center align-items-center">
            {/*<div className="d-flex h-25 text-center align-items-center">*/}
            <div
              style={{
                color: "#ffffff",
                zIndex: 1,
                marginTop: "400px",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h3 style={{ marginTop: "15px" }}>
                  Advertising Jobs Effectively
                  <br />
                  <FontAwesomeIcon icon={faPlus as IconProp} /> Recruiting Right
                  Workers
                  <br />
                  <FontAwesomeIcon icon={faEquals as IconProp} /> Strengthening
                  Churches
                </h3>
              </div>
              <p>
                Vacancies.church is a bespoke jobs hub to help with advertising
                and recruiting gospel workers for churches.
              </p>
              <hr />
              <p style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ margin: "10px" }}
                  outline={true}
                  size="lg"
                  color="light"
                  href="/jobs"
                >
                  View jobs
                </Button>
                <Button
                  style={{ margin: "10px" }}
                  outline={true}
                  size="lg"
                  color="light"
                  href="/jobs/add"
                >
                  Post a job
                </Button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export const getServerSideProps = withAuthUserTokenSSR()();

export default withAuthUser()(Home);
