import { useState } from "react";
import {
  useAuthUser,
  //withAuthUser,
  //withAuthUserTokenSSR,
} from "next-firebase-auth";
//import Link from 'next/link';
import { useRouter } from "next/router";
import Head from "next/head";

import {
  Navbar,
  UncontrolledDropdown,
  NavLink,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  //Container, Row, Col, NavbarText
} from "reactstrap";

export default function Header({ admin }: { admin?: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const AuthUser = useAuthUser();

  return (
    <div>
      <Navbar color="light" expand="md" light fixed="top">
        <NavbarBrand href="/" aria-label="Home">
          {/* //TODO <Image src="/logo.png" width={50} height={50} />*/}
          <img
            src="https://s3.eu-west-1.amazonaws.com/vacancies.church/logo_vc.png"
            height="69"
            width="200"
            alt="Vacancies.church logo"
          />
        </NavbarBrand>
        <NavbarToggler
          onClick={function noRefCheck() {
            setIsOpen(!isOpen);
          }}
        />
        <Collapse navbar isOpen={isOpen}>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink href="/jobs">All jobs</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/jobs/page/ministry-training">
                Ministry training
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/jobs/add">Post a job</NavLink>
            </NavItem>
            {AuthUser.email ? (
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  {AuthUser.email}
                </DropdownToggle>
                <DropdownMenu end>
                  {admin ? (
                    <DropdownItem onClick={() => router.push("/admin")}>
                      Admin
                    </DropdownItem>
                  ) : (
                    <></>
                  )}
                  <DropdownItem onClick={() => router.push("/recruiter")}>
                    Recruiter
                  </DropdownItem>
                  <DropdownItem onClick={() => router.push("/job-seeker")}>
                    Job-seeker
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => AuthUser.signOut()}>
                    Log out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <>
                <NavItem>
                  <NavLink href="/login">Login / Sign up</NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}
